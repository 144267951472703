import './App.css';
import { useEffect } from 'react';

function App() {
  const wrap = (text) => {
    const splitted = text.split(' ');
    return splitted.map((e, index) => (
      <>
        <span class={index == 0 ? 'dsa' : ''}>{e}</span>{' '}
      </>
    ));
  };
  window.addEventListener('scroll', () => {
    const textToAnimate = document.querySelector('#animated-text');
    const splittedText = textToAnimate.innerHTML.split(/<\/?span>/);

    const scrollableHeight =
      document.documentElement.scrollHeight - window.innerHeight;

    const texts = document.querySelectorAll('span');
    const start = window.scrollY;
    if (start > 0) {
      const finish = scrollableHeight;
      const percent = Math.floor((start / finish) * splittedText.length);

      for (let i = 0; i < texts.length; i++) {
        if (i === percent) {
          texts[percent].classList.add('dsa');
        } else {
          texts[i].classList.remove('dsa');
        }
        if (i < percent) {
          texts[texts.length - 1].classList.add('dsa');
        }
      }
    }
  });

  return (
    <div className='App'>
      <header className='welcome-header'>
        <div class='big-text text-center grey-text'>MALTECT.</div>
        <div class='lightgrey-text big-text text-center' id='animated-text'>
          {wrap(
            'Moderne it-løsninger til både den private og offentlige sektor.'
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
